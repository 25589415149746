define("discourse/plugins/discourse-custom-composer-fields/discourse/components/ccf-field", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object"], function (_exports, _decorators, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CcfField extends _component.default {
    classNames = ["ccf__field"];
    attributeBindings = ["customStyle:style"];
    customStyle(mobileView, mobileWidth, desktopWidth) {
      const width = mobileView ? mobileWidth || "100%" : desktopWidth || "33.33%";
      return `width: ${width}`;
    }
    static #_ = (() => dt7948.n(this.prototype, "customStyle", [(0, _decorators.default)("site.mobileView", "field.width.mobile", "field.width.desktop")]))();
    format() {
      this.element.querySelectorAll("input").forEach(input => {
        input.classList.remove("ccf__required-error");
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "format", [_object.action]))();
    placeholder(placeholder, name) {
      return placeholder || name;
    }
    static #_3 = (() => dt7948.n(this.prototype, "placeholder", [(0, _decorators.default)("field.placeholder", "field.name")]))();
  }
  _exports.default = CcfField;
});